<template>
    <div>
        <el-page-header @back="$router.go(-1)" content="节假日设置"/>
        <el-form :model="form" :rules="rules" ref="form" label-width="100px" class="form">
            <el-form-item label="名称" prop="name">
                <el-input v-model="form.name" placeholder="请输入名称"></el-input>
            </el-form-item>
            <el-form-item label="日期" prop="dates">
                <select-dates v-model="form.dates"></select-dates>
            </el-form-item>
<!--            <el-form-item label="医生" prop="doctor">-->
<!--                <radio-doctor v-model="form.doctor" :all="true"/>-->
<!--            </el-form-item>-->
            <el-form-item label="备注" prop="desc">
                <el-input type="textarea" v-model="form.desc"></el-input>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" @click="submitForm('form')">保 存</el-button>
                <el-button @click="$router.go(-1)">返 回</el-button>
            </el-form-item>
        </el-form>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                form: {name:'', dates:[], doctor:'', desc:''},
                rules: {
                    name: [
                        {required: true, message: '请输入名称', trigger: 'blur'}
                    ],
                    dates: [
                        {required: true, message: '请选择日期', trigger: 'change'}
                    ],
                }
            };
        },
        methods: {
            async submitForm(formName) {
                const valid = await this.$refs[formName].validate().then(res => res).catch(error => error);
                if (!valid) {
                    return
                }
                let resp = await this.$http.restPostOrPut('/surgery/vacation/', this.form)
                if (resp.code == 200) {
                    this.$message.success("保存成功！")
                    await this.$router.replace("/main/worksheet/vacation")
                } else {
                    this.$message.error(resp.msg)
                }
            },
            async getData() {
                let id = this.$route.query.id
                if (id) {
                    const resp = await this.$http.get(`/surgery/vacation/${id}`)
                    this.form = resp.data.data
                }
            }
        },
        mounted() {
            this.getData()
        }
    }
</script>

<style lang="scss" scoped>
    .form {
        margin-top: 20px;
        max-width: 600px;
    }
</style>
